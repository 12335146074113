import AboutContent from "../content/about";
import ModalForm from "../components/default/modal";
import ICONS from "../components/default/icons";
import React from "react";
import {useTranslation} from "react-i18next";

function AboutPage() {

    const { t, i18n } = useTranslation()

    const _lang = i18n.language;

    return (

        <div className={'container m-auto'} style={{ paddingTop: "15vh"}}>
            <h1>
                {t('nav.about')}
            </h1>
            {/*https://www.plusdevelopment.in.ua/pro-plus-development*/}


            <AboutContent />

            <ModalForm
                type={'lead'}
                title={t('page.btn-connect').toUpperCase()}
                class={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3 col-md-4 col-12'}
                // icon={ICONS[0].phone}
            />
        </div>

    );
}

export default AboutPage;
