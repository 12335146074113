import {useParams} from "react-router";
import React, {Suspense, useEffect, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import { lazy } from 'react';
import Zoom from 'react-medium-image-zoom'

const SimilarPosts = lazy(() => import('../../content/posts'));

function PostSingle(props){


    const { t, i18n } = useTranslation()

    let {slug} = useParams();

    const [data, setData] = useState([]);
    const [load, setLoad] = useState(true);

    const _fetch = () => {
        axios.get('https://api.zhytomyr.land/api/content/post/' + slug, {
            header: {}
        }).then( response => setData(response.data.data))
            .catch(e => console.log(e.message()))
            .finally( () => setLoad(false))
    }

    useEffect(() => {
        _fetch();
    }, []);

    const _shareButtons = [
        "facebook",
        "email",
        "twitter",
        "telegram",
        "whatsapp",
        "viber",
        "linkedin"
    ]

    const _shareButtonsLink = [
        "http://www.facebook.com/sharer.php?u=",
        "mailto:?Subject=ZhytomyrLand&Body=",
        "http://twitter.com/share?url=",
        "https://telegram.me/share/url?url=h",
        "https://api.whatsapp.com/send?text=",
        "viber://forward?text=",
        "http://www.linkedin.com/shareArticle?mini=true&url=h"
    ]

    return <>

        {load ? 'Loading...' : ''}
        <div className="container col-md-6 m-auto col-12" style={{ paddingTop: '8vh'}}>

            {data.image && <Zoom> <img
                style={{height: '40vh', width: "100%", objectFit: "cover"}}
                src={data.image} alt="image-cover" className={'img-fluid rounded-bottom'}
            /></Zoom>}

            <small className="text-secondary p-2 pt-0">
                {i18n.language === 'en' ? data.category?.name?.en : data.category?.name?.ua} | {data.data}
            </small>

            <h1 className={'mt-5'}>{i18n.language === 'en' ? data.title?.en : data.title?.ua}</h1>

            <p className={'mt-5'}>
                <div dangerouslySetInnerHTML={{__html: i18n.language === 'en' ? data.body?.en : data.body?.ua }} />
            </p>

            <div className="share-buttons mt-5">

                <div className="d-flex flex-wrap justify-content-between align-items-center">

                    {
                       // JSON.stringify(data?.share).split(',')[0].split(':"')[1]
                    }
                    <div className={'col-12'}>
                        <div className={'text-secondary- color'}>{t('title-nav.share-link')}</div>
                    </div>

                    {_shareButtons.map((value, i) => <div key={i} className={'p-2 col-4'}>
                        <a href={_shareButtonsLink[i] + 'https://zhytomyr.land/posts/' + data.slug} className={'text-decoration-none'}>
                            {value}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                            </svg>
                        </a>
                    </div>)}

                </div>
            </div>

            <h4 className={'mt-5'}>{t('title-nav.share')}</h4>

            <Suspense fallback={<div>{t('page.load')}</div>}>
                <SimilarPosts btnMore={false} page={false} limit={4} />
            </Suspense>


        </div>

    </>
}

export default PostSingle;