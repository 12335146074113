import PostsContent from "../content/posts";
import React from "react";

function PostsPage() {
    return (

        // http://localhost:3000/api/data/posts.json
        // Статичні пости без бази даних, 4 - 8 постів

        // 1. Як обрати земельну длянку / Основні аспекти при виборі земельної ділянки
        // 2.
        // 3. Як документи потрібні для будівництва будинку
        // 4. Як купити земельну ділянку
        // 5.
        // 6.https://sokilnyky.com/taunkhaus-dupleks-chy-pentkhaus-shcho-obraty/
        // https://mayak.kiev.ua/news/shcho-take-taunkhaus
        // https://www.ak-projects.com.ua/scho-take-taunhaus/
        // 7. https://lexstatus.ua/ua/dozvil-na-budivnytstvo/
        // 8.

        <>
            <div className="container" style={{marginTop: '15vh'}}>
                <PostsContent btnMore={false} page={true} />
            </div>
        </>

    );
}

export default PostsPage;
