
import React from "react";

const LayoutContentPage = (props) => {
    return (
        <main className="container-fluid min-vh-100">

            {props.children}

        </main>
    )
};

export default LayoutContentPage;