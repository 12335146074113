import SectionComponent from "../components/default/section";
import {useTranslation} from "react-i18next";
import {MotionAnimate} from "react-motion-animate";
import React from "react";


function AdvantagesContent () {

    // Переваги
    // Перше котеджне містечко в Житомирі побудоване централізовано і за єдиним планом.
    //
    //     Орієнтоване на екологічність, функціональність, доступність.
    //
    //     Площею 10 гектарів(100 000 м2) в зеленій зоні на околиці м. Житомир.

    const { t, i18n } = useTranslation();
    const _lang = i18n.language;

    const _data = {
        en: [
            "No. 1 centralized cottage settlement",
            "The infrastructure is close at hand",
            "Harmony with nature (reservoirs, forest)",
            "Special conditions for purchase"
        ],
        ua: [
            "№1-й централізований котеджний поселок",
            "Інфраструктуа в близкій доступності",
            "Гармонія з природою (водойма, ліс)",
            "Спеціальні умови на купівлю"
        ]
    }

    const _icons = [
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
             className="bi bi-file-earmark-spreadsheet text-secondary" viewBox="0 0 16 16">
            <path
                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5zM3 12v-2h2v2zm0 1h2v2H4a1 1 0 0 1-1-1zm3 2v-2h3v2zm4 0v-2h3v1a1 1 0 0 1-1 1zm3-3h-3v-2h3zm-7 0v-2h3v2z"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
             className="bi bi-shop" viewBox="0 0 16 16">
            <path
                d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
             className="bi bi-tree text-success" viewBox="0 0 16 16">
            <path
                d="M8.416.223a.5.5 0 0 0-.832 0l-3 4.5A.5.5 0 0 0 5 5.5h.098L3.076 8.735A.5.5 0 0 0 3.5 9.5h.191l-1.638 3.276a.5.5 0 0 0 .447.724H7V16h2v-2.5h4.5a.5.5 0 0 0 .447-.724L12.31 9.5h.191a.5.5 0 0 0 .424-.765L10.902 5.5H11a.5.5 0 0 0 .416-.777zM6.437 4.758A.5.5 0 0 0 6 4.5h-.066L8 1.401 10.066 4.5H10a.5.5 0 0 0-.424.765L11.598 8.5H11.5a.5.5 0 0 0-.447.724L12.69 12.5H3.309l1.638-3.276A.5.5 0 0 0 4.5 8.5h-.098l2.022-3.235a.5.5 0 0 0 .013-.507"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
             className="bi bi-bag-heart text-danger" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M14 14V5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1M8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132"/>
        </svg>
    ];

    return <>
        <MotionAnimate
            delay={0.4}
            speed={2}
            ease={[0.75, 0.45, 0.53, 0.94]}
            reset={true}>
            <div className="d-flex flex-wrap p-1 pt-5 pb-5 mt-5 mb-5">

                {
                    _icons.map((icon, i) =>
                    <div key={i} className="col-md-3 col-6 text-center p-md-0 p-3">
                        {icon}
                        <br/> <br/>
                        <small className="text-secondary p-2">{_data[_lang][i]}</small>
                    </div>
                    )
                }

            </div>
        </MotionAnimate>
        </>
}

export default AdvantagesContent;