import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'ua',
        backend: {
            /* translation file path */
            loadPath: '/assets/files/{{ns}}/{{lng}}.json'
        },
        fallbackLng: 'ua',
        debug: true,
        nsSeparator: ".",
        ns: ['translations'],
        defaultNS: 'translations',
        // keySeparator: true,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            wait: true
        }
    })

export default i18n;
