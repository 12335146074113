

function SectionComponent(props) {
    return (

        <section id={props.id} className={props.class + ' section'}>

            <div className={props.full ? 'container-fluid' : 'container'}>
                <h3>
                    {props.title}
                </h3>

                {props.children}
            </div>

        </section>

    );
}

export default SectionComponent;
