import {Link} from "react-router-dom";
import React from "react";
import LanguageComponent from "./language";
import {useTranslation} from "react-i18next";
import ICONS from "./icons";
import Navigationbar from "./navbar";

function HeaderComponent() {

    const { t, i18n } = useTranslation()

    return (

            <>
                <div className={'d-block d-md-none'}>
                    <header className={'bg-secondary'}>

                        {/*<Link to="/" className={'d-flex align-items-center justify-content-center'}>*/}
                        {/*    <img src="/assets/img/logo.png" alt="logo" className={'m-1'} width={'50'}/>*/}
                        {/*    <h3 className={'text-white fs-2'}>*/}
                        {/*        <>*/}
                        {/*            Zhytomyr Land*/}
                        {/*        </>*/}
                        {/*    </h3>*/}
                        {/*</Link>*/}

                        <Navigationbar />
                    </header>
                </div>

                <div className="d-none d-md-block">

                <header className="mulish-font fixed-top bg-white- background background-border m-auto container-fluid d-flex flex-wrap justify-content-around align-items-center">

                    <div id={'language'} className="col-md-3 text-center">

                        <LanguageComponent />

                    </div>

                    <div id={'nav-logo'} className="col-md-6 justify-content-center d-flex align-items-center pt-2">

                        <nav className={'col-md-4 text-end'}>
                            <ul className={'list-unstyled pt-2'}>
                                <li className="d-inline p-1 nav-link">
                                    <Link to="/about-us">
                                        {t("nav.about")}
                                    </Link>
                                </li>
                                <li className="d-inline p-1 nav-link">
                                    <Link to="/projects">
                                        {t('nav.projects')}
                                    </Link>
                                </li>
                                <li className="d-inline p-1 nav-link">
                                    <Link to="/land">
                                        {t('nav.land')}
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                        <div className={'p-2 col-md-4 text-center'}>
                            <Link to="/" className={'d-flex align-items-center justify-content-center'}>
                                <img src="/assets/img/logo.png" alt="logo" className={'m-1'} width={'50'}/>
                                <h3 className={'text-white fs-2'}>
                                    <>
                                        Zhytomyr Land
                                    </>
                                </h3>
                                {/*<p>*/}
                                {/*    Продаж земельних ділянок в м.Житомир*/}
                                {/*</p>*/}
                            </Link>
                        </div>

                        <nav className={'col-md-4 text-start'}>
                            <ul className={'list-unstyled pt-2'}>
                                <li className="d-inline p-1 nav-link">
                                    <Link to="/posts">
                                        {t('nav.posts')}
                                    </Link>
                                </li>
                                <li className="d-inline p-1 nav-link">
                                    <Link to="/faq">
                                        {t('nav.faq')}
                                    </Link>
                                </li>
                                <li className="d-inline p-1 nav-link">
                                    <Link to="/contacts">
                                        {t('nav.contacts')}
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                    </div>
                    <div id={'phone'} className="col-md-3 text-center">
                        <a
                            className=""
                            href="tel.:+380 67 281 38 46"
                            // target="_blank"
                            rel="noopener noreferrer"
                        >
                            <b>
                                {/*{ICONS[0].phone}  */}
                                +380 67 281 38 46</b>
                        </a>
                    </div>
                </header>
                </div>
            </>
    );
}

export default HeaderComponent;
