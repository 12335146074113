import React from "react";
import ICONS from "../components/default/icons";
import ModalForm from "../components/default/modal";
import {useTranslation} from "react-i18next";
import DATA_INFO from "../data/data_info";

function InfoPage() {

    const { t, i18n } = useTranslation()

    const _lang = i18n.language;

    const _data = DATA_INFO[0];

    return (

        <div className={'container m-auto mt-5 pt-5 info-page'}>

            <h1 className={'display-1'}>{t('nav.faq')}</h1>
            <h6 className={'text-secondary'}>{t('info.subtitle')}</h6>





            <div className=" mt-5">

                <div className="p-0 col-md- m-auto bg-white">

                    <i className={'text-secondary'}>{t('info.title1')}</i>

                        <div className="scrollmenu">

                            {
                                _data.qa[_lang].map((item, i) =>
                                    <a key={i} className="btn btn-light border m-1" href={'#' + item.link}>{item.q}</a>
                                )
                            }

                            <a className="btn btn-light border m-1" href="#privacy-policy">{t('nav.policy')}</a>
                        </div>

                </div>

                <div className="col-md-8 m-auto mt-5">
                    <i className={'text-secondary'}>{t('info.title2')}</i>

                    {
                        _data.qa[_lang].map((item, i) => <div key={i} id={item.link} className={'mt-5'}>
                            <h3 className={'display- lead1'}>
                                <b>
                                    {item.q}
                                </b>
                            </h3>
                            <p className={'mt-3'}>
                                {item.a}
                            </p>
                        </div>)
                    }


                    <ModalForm
                        type={'lead'}
                        title={t('page.btn-connect').toUpperCase()}
                        class={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3 col-md-4 col-12'}
                        // icon={ICONS[0].phone}
                    />

                    <div id="privacy-policy" className={'mt-5'}>

                        <i className={'text-secondary'}>{t('info.title3')}</i>

                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item1 rounded-0 bg-color">
                                <h2 className="accordion-header bg-color" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <h6 className={''}>
                                            {/*{_data.basic.title[_lang].toLowerCase()}*/}
                                            {t('nav.policy')}
                                        </h6>
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show"
                                     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">

                                        <div dangerouslySetInnerHTML={{__html: _data.basic.description[_lang]}} />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    );
}

export default InfoPage;
