import React, {useState} from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import {useTranslation} from "react-i18next";

function ModalForm(props) {

    const { t, i18n } = useTranslation()

    const _lang = i18n.language;

    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [comment, setComment] = useState("");

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [result, setResult] = useState(false);

    const [error, setError] = useState(false);

    const onLoginFormSubmit = (e) => {
        e.preventDefault();
        handleClose();
    };

    const _checkBeforeSendForm = () => {

        if(
            props.type === 'recall' && (name === '' ||
            phone === '')
        ){
            setError(true);
        }else if(
            (props.type === 'lead' || props.type === 'feedback')
            && (name === '' || phone === '' ||email === '' || comment === '')
        ){
            setError(true);
        }
        else{
            _submitForm();
        }
    }

    const _submitForm = () => {

        const _data = {
            comment: comment,
            phone: phone,
            name: name,
            email: email,
            type: props.type.toUpperCase()
        }

        try{

            axios.post(
                'https://api.zhytomyr.land/api/claim/send/to-telegram'
                , _data, {
                headers: {

                }
            })
                .then(res => {

                     setResult(res.data.success)
                    // setResult(true);
            })
                .catch(e => console.log(e))
                .finally(() => {

                    setTimeout(() => {
                        handleClose()
                    }, 2500)
            })

        }catch (e){
            console.log(e)
        }
    }

    /*
    * recall - phone + name
    * lead - name, phone, comment
    * feedback - name, email, phone, comment
    * */

    return <>


        <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "10vh" }}
        >
            <Button variant="primary" onClick={handleShow} className={props.class}>
                {props.title}
                {props.icon}
            </Button>
        </div>
        <Modal show={show} onHide={handleClose} className={''}>
            <Modal.Header closeButton>
                <Modal.Title className={'text-center text-uppercase'}>
                    {t('modal.title.' +  props.type)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={''}>

                {/*<Form onSubmit={_submitForm} className={''}>*/}

                {
                    result ? <div className={'text-center pt-3'}>
                        <h2 className={'color'}>
                            {t('modal.result.title')}
                        </h2>
                        <p className={'mt-3 text-secondary'}>
                            {t('modal.result.description')}
                        </p>
                        <button className={'mt-3 btn btn-dark rounded-0 shadow text-decoration-none color background p-3 w-100'}>
                            {t('page.error-btn')}
                        </button>
                    </div> : <>
                        <div className={'text-center'}>
                            {
                                error && <div className={'text-danger'}>
                                    <b>{t('modal.error')}</b>
                                </div>
                            }
                        </div>
                        <Form.Group controlId="formBasicEmail">
                            {/*<Form.Label>Email address</Form.Label>*/}

                            {props.type === 'recall' &&
                            <div>
                                <Form.Control
                                    type="text"
                                    placeholder={t('modal.field.name')}
                                    value={name}
                                    // required={true}
                                    className={'border-0 rounded-0 border-bottom'}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <Form.Control
                                    type="tel"
                                    placeholder={t('modal.field.phone')}
                                    value={phone}
                                    // required={true}
                                    className={'border-0 rounded-0 mt-2 border-bottom'}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                            }
                            {(props.type === 'lead' || props.type === 'feedback') &&
                            <>
                                <Form.Control
                                    type="text"
                                    placeholder={t('modal.field.name')}
                                    value={name}
                                    // required={true}
                                    className={'border-0 rounded-0 border-bottom'}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <Form.Control
                                    type="tel"
                                    placeholder={t('modal.field.phone')}
                                    value={phone}
                                    // required={true}
                                    className={'border-0 rounded-0 mt-2 border-bottom'}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <Form.Control
                                    type="email"
                                    placeholder={t('modal.field.email')}
                                    value={email}
                                    className={'border-0 rounded-0 mt-2 border-bottom'}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Form.Control as="textarea" rows={3}
                                              placeholder={t('modal.field.comment')}
                                              value={comment}
                                              onChange={(e) => setComment(e.target.value)}
                                              className={'border-0 rounded-0 mt-2 border-bottom'}
                                />
                            </>
                            }
                        </Form.Group>


                        <Button variant="primary" onClick={_checkBeforeSendForm} type="submit" block className={'mt-5 btn btn-dark rounded-0 shadow text-decoration-none color background p-3 w-100'}>
                            {t('modal.btn-send')}
                        </Button>

                    </>
                }



                {/*</Form>*/}

            </Modal.Body>
            <Modal.Footer>
                {/*<Form.Group controlId="formBasicCheckbox">*/}
                {/*    <Form.Check type="checkbox" checked={true} label="Даю згоду на обробку моїх данних" />*/}
                {/*</Form.Group>*/}
                <small className={'text-secondary'}>
                    {t('modal.info')}
                </small>
                <Button variant="light" onClick={handleClose}>
                    {t('modal.btn-close')}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default ModalForm;