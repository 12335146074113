import {Link} from "react-router-dom";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import ICONS from "./icons";
import ModalForm from "./modal";
import axios from "axios";


function FooterComponent() {

    const { t, i18n } = useTranslation()

    const _icon = ICONS[0];

    const [email, setEmail] = useState('');
    const [result, setResult] = useState(false);

    const _subscribeForm = () => {

        if (email === ''){
            alert('Email empty!')
        }else{
            const _data = {
                comment: '',
                phone: '',
                name: '',
                email: email,
                type: 'subscribe'.toUpperCase()
            }

            axios.post('https://api.zhytomyr.land/api/claim/send/to-telegram', _data, {
                headers: {
                }
            }).then(res => {
                setResult(res.data.success)
            }).catch(e => console.log(e)).finally(()=>{
                setEmail('')
            })
        }

    }

    return (

        <footer className="footer border container-fluid mulish-font">

            <div className="container d-flex justify-content-between align-items-center">

                <div className="question pt-3">
                    <h3>
                        {t('page.q1')}
                    </h3>
                </div>

                <div className="btn-modal-form">
                    <ModalForm
                        type={'feedback'}
                        title={t('page.modal.t1')}
                        class={'bg-white btn-btn-warning btn border text-dark mt-3 bg-white color background p-3 rounded-0'}
                    />
                </div>

            </div>

            <div className="container d-flex flex-wrap justify-content-around flex1-wrap-reverse pb-5 pt-5 mt-3 border-top">

                <div className="col-md-3 col-12 p- pt-0 pb-0 text-start">

                    <h3 className={'fs-3 background p-1 col-md-10'}>
                        <img src="/assets/img/logo.png" alt="logo" className={'m-1'} width={'50'}/>
                        Zhytomyr Land
                    </h3>
                    <p>

                    </p>
                    <p className={'fw-bold'}>
                        {t('page.location')} {_icon.location} (c.Іванівка)
                    </p>

                    <a
                        className="phone-link fw-bold"
                        href="tel.:+380 67 281 38 46"
                        // target="_blank"
                        rel="noopener noreferrer"
                    >
                        {_icon.phone}  +380 67 281 38 46
                    </a>

                    <p className={'mt-3 d-none d-md-block'}>
                        © Zhytomyr Land 2024
                    </p>
                </div>
                <div className="col-md-3 col-12 d-none d-md-block">

                    <h5>
                        {t('nav.app')}
                    </h5>
                    <img src="https://growthukraine.com/assets/app/android.svg" alt="google-play" className={'mt-2'}/>
                    <small className="text-secondary p-3">
                        {t('coming-soon')}
                    </small>

                </div>
                <div className="col-md-4 col-12 mt-3 mt-md-0">

                    <div className="row">
                        <div className="col-md-6 col-12">
                            <h5>
                                {t('nav.projects')}
                            </h5>
                            <ul className={'list-unstyled'}>
                                {/*<li className="p-1">*/}
                                {/*    <Link to="/projects">*/}
                                {/*        {t('nav.projects')}*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li className="p-1">
                                    <Link to="/land" className={'fw-bold'}>
                                        {t('nav.lands')}
                                    </Link>
                                </li>
                                <li className="p-1">
                                    <Link to="/projects/house">
                                        {t('nav.houses')}
                                    </Link>
                                </li>
                                <li className="p-1">
                                    <Link to="/projects/town-house">
                                        {t('nav.townhouse')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-12">
                            <h5>{t('nav.other')}</h5>
                            <ul className={'list-unstyled'}>
                                <li className="p-1">
                                    <Link to="/about-us">
                                        {t("nav.about")}
                                    </Link>
                                </li>
                                <li className="p-1">
                                    <Link to="/faq#how-to-buy" className={'fw-bold'}>
                                        {t('nav.to-buy')}
                                    </Link>
                                </li>
                                <li className="p-1">
                                    <Link to="/posts">
                                        {t('nav.posts')}
                                    </Link>
                                </li>
                                <li className="p-1">
                                    <Link to="/contacts">
                                        {t('nav.contacts')}
                                    </Link>
                                </li>
                            </ul>

                            <p className={'mt-5 d-block d-md-none'}>
                                © Zhytomyr Land 2024
                            </p>
                        </div>
                    </div>

                </div>
                <div className="col-md-2 col-12 d-none d-md-block">

                    <div className="">
                        <h5>
                            {t('nav.subscribe')}
                        </h5>
                        <br/>
                        {
                            result ? <h5 className={'color'}>
                                    {t('nav.thank')}
                                </h5> :
                         <div className="d-flex">
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className={'p-2 border-0 rounded-0 border-white col-md-10'} placeholder={'Email'} />
                            <button onClick={_subscribeForm} className="btn btn-light rounded-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                </svg>
                            </button>
                        </div>
                        }
                    </div>

                    <br/>
                    <a href="/information#privacy-policy">
                        {t('nav.policy')}
                    </a>

                    <br/><br/>
                    <p>
                        {/*<a href="" className={'m-1'}>*/}
                        {/*    {_icon.telegram} Telegram*/}
                        {/*</a>*/}
                        <a href="whatsapp://send?phone=+380672813846" className={'m-1'}>
                            {_icon.whatsapp} WhatsApp
                        </a>
                    </p>
                    <p>
                        {/*OLX | DOM.RIA | RIELTOR.UA*/}
                    </p>
                </div>

            </div>

        </footer>
    );
}

export default FooterComponent;
