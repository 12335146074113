import ICONS from "../components/default/icons";
import {useTranslation} from "react-i18next";
import DATA_PROJECTS from "../data/data_projects";
import Zoom from 'react-medium-image-zoom'
import {MotionAnimate} from "react-motion-animate";
import SectionComponent from "../components/default/section";
import React from "react";

function ProjectsContent () {

    const { t, i18n } = useTranslation()

    const _data = DATA_PROJECTS;

    const _lang = i18n.language;

    return <>
            <div className="container min-vh-100 d-flex justify-content-center align-items-center">

                <div className={'pb-3 pt-5'}>
                    <div className="d-flex justify-content-between align-items-center mb-5 pt-5">
                        <div>
                            <h2 className={'display-3'}>
                                {t('nav.our-projects')}
                            </h2>
                        </div>

                        <div>
                            <a href="https://www.google.com/maps/@50.2835821,28.5376555,889m/data=!3m1!1e3?entry=ttu" className={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3'} target={'_blank'}>{ICONS[0].location} {t('nav.map-view')}</a>
                        </div>
                    </div>


                    <div className="d-flex flex-wrap pt-5">

                        {
                            _data.map((item, i) =>
                                <div className="col-md-4 col-12" key={i}>
                                    <MotionAnimate
                                        animation='fadeInUp'
                                        reset={true}
                                        distance={200}
                                        delay={1+i}
                                        speed={1}>
                                    <div className="m-2 p-2">
                                        <a href={item.slug} className={'text-decoration-none text-dark'}>
                                            <div className="image-cover">
                                                <Zoom> <img src={item.url} alt="land plots" className={'img-fluid rounded-top'}/> </Zoom>
                                            </div>

                                            <h3 className={'fs-2 mt-3'}>{item.title[_lang]}</h3>
                                            <div className="d-flex flex-wrap justify-content-between">
                                                {
                                                    item.options && item.options.map((parameter, j) =>
                                                        <small key={j} className="text-secondary d-flex align-items-center">
                                                            <div className="p-1">
                                                                {parameter.icon ?? '-'}
                                                            </div>
                                                            <div className="p-1">
                                                                {parameter.name[_lang] ?? '-'}
                                                            </div>
                                                        </small>
                                                    )
                                                }
                                            </div>
                                        </a>
                                    </div>
                                    </MotionAnimate>
                                </div>
                            )
                        }

                    </div>

                </div>

            </div>
        </>
}

export default ProjectsContent;