import SectionComponent from "../components/default/section";
import React, {lazy, Suspense} from "react";
import {useTranslation} from "react-i18next";

const SimilarPosts = lazy(() => import('../content/posts'));
const Video = lazy(() => import('../content/video'));
const Advantages = lazy(() => import('../content/advantages'));
const About = lazy(() => import('../content/about'));
const Projects = lazy(() => import('../content/projects'));
const Slogan = lazy(() => import('../content/slogan'));
const Slider = lazy(() => import('../content/intro-slider'));

function MainPage() {

    const { t, i18n } = useTranslation()

    return (

        <>
            {/*SLIDER*/}
            <Suspense fallback={<div>{t('page.load')}</div>}>
                <SectionComponent
                    id={'main'}
                    title={''}
                    class={'background2 pt-5 mt-2'}
                    full={true}
                >
                    <Slider />
                </SectionComponent>
            </Suspense>

            {/*ABOUT*/}
            <Suspense fallback={<div>{t('page.load')}</div>}>
                <SectionComponent
                    id={'about'}
                    title={''}
                >
                    <About />
                </SectionComponent>
            </Suspense>

            {/*SLOGAN*/}
            <Suspense fallback={<div>{t('page.load')}</div>}>
                <SectionComponent
                    id={'slogan'}
                    title={''}
                    class={'background'}
                >
                    <Slogan/>
                </SectionComponent>
            </Suspense>

            {/*PROJECTS*/}
            <Suspense fallback={<div>{t('page.load')}</div>}>
                <SectionComponent
                    id={'projects'}
                    title={''}
                    class={'background2'}
                >
                    <Projects />
                </SectionComponent>
            </Suspense>


            {/*ADVANTAGES*/}
            <Suspense fallback={<div>{t('page.load')}</div>}>
                <SectionComponent
                    id={'advantages'}
                    title={''}
                >
                        <Advantages />
                </SectionComponent>
            </Suspense>

            {/*VIDEO*/}
            <Suspense fallback={<div>{t('page.load')}</div>}>
                <SectionComponent
                    id={'video'}
                    title={''}
                    class={'background2'}
                >
                    <Video />
                </SectionComponent>
            </Suspense>

            {/*POSTS*/}
            <Suspense fallback={<div>{t('page.load')}</div>}>
                <SectionComponent
                    id={'posts'}
                    title={''}
                >
                    <SimilarPosts btnMore={false} page={false} limit={4} />
                </SectionComponent>
            </Suspense>

        </>

    );
}

export default MainPage;
