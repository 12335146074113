
import {useTranslation} from "react-i18next";

function NoPage() {

    const { t, i18n } = useTranslation()

    const _lang = i18n.language;

    return (

        <div className={'container m-auto'} style={{ paddingTop: "15vh"}}>
            <h1>

                {t('page.error')}
            </h1>

            <small className={'text-secondary'}>
                {t('page.error-subtitle')}
            </small>
            <p>
                {t('page.error-description')}
            </p>
            <a href="/" className={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3'}>
                {t('page.error-btn')}
            </a>
        </div>

    );
}

export default NoPage;
