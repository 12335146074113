const DATA_INFO = [
    {
        basic: {
            title: {
                ua: "ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ ТА ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ",
                en: "PRIVACY POLICY AND PROCESSING OF PERSONAL DATA"
            },
            description: {
                ua: "<p>\n" +
                    "                                            Згідно Закону України “Про захист персональних даних”, заповнюючи дані реєстраційної форми (замовлення) та проставляючи відмітку про ознайомлення з даною Політикою конфіденційності та обробки персональних даних (далі – Політика конфіденційності) Ви даєте згоду на обробку Ваших персональних даних з метою забезпечення реалізації цивільно-правових відносин, при видачі/продовженні невиключної (их) ліцензії (її) на використання комп’ютерної(их) програми (м).\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            Ми цінуємо Ваше право на особисте життя та нерозголошення Ваших персональних даних. Ця Політика конфіденційності – правила, якими користуються всі співробітники нашої компанії, та регламентує збір і використання персональних даних, які може бути запитані/отримані при придбанні комп’ютерної(их) програми (м).\n" +
                    "                                        </p>\n" +
                    "                                        <p className={''}>\n" +
                    "                                            <b>1. Обсяг персональних даних</b>\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            При здійсненні замовлення придбання комп’ютерної(их) програми(м) та заповненні реєстраційної форми (замовлення), Ви надаєте для обробки свої персональні дані – зокрема, ім’я, адреси, номера телефону, e-mail, банківських даних, тощо. Такі дані ми отримуємо лише від осіб, які надають їх свідомо та з власного бажання. Для того щоб зробити замовлення комп’ютерної(их) програми(м) чи іншим чином взаємодіяти з нами, Ви повинні уважно ознайомитися з Вашими правами та обов’язками щодо обробки персональних даних, які зазначені в ст. 8 Закону України «Про захист персональних даних», уважно ознайомитися з даною Політикою конфіденційності, а також висловити свою повну згоду з їх умовами.\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            <b>2. Мета збору персональних даних</b>\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            Персональні дані – відомості чи сукупність відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована. Ми можемо обробляти Ваші персональні дані для наступних цілей. При цьому одночасно можуть застосовуватися одна або кілька цілей:\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            Отримання замовлення. Ми можемо використовувати Ваші персональні дані для отримання замовлення, яке Ви зробили, для обробки Ваших запитів, або для інших цілей, які можуть існувати для досягнення кінцевої мети – задовольнити інтереси споживача, а також для запобігання та розслідування випадків шахрайства та інших зловживань.\n" +
                    "                                            Спілкування з Вами. Ми можемо використовувати Ваші персональні дані для зв’язку з Вами, наприклад повідомити Вас про зміну у наших продуктах або надіслати Вам важливі повідомлення та інші подібні повідомлення, що стосуються замовлення, що було Вами зроблено та зв’язатися з Вами в цілях, пов’язаних з обслуговуванням споживача/клієнта.\n" +
                    "                                            Ми діємо відповідно до цієї Політики конфіденційності, на підставі Положення про обробку і захист персональних даних та на підставі чинного законодавства України.Ми маємо право зберігати Персональні дані стільки, скільки необхідно для реалізації мети, що зазначена у даній Політиці або у строки, встановлені чинним законодавством України або до моменту видалення Вами цих даних.\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            <b>3. Передача персональних даних третім особам</b>\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            Ми не продаємо, не передаємо та не розголошуємо персональні дані, яку отримуємо на нашому сайті, третім сторонам без Вашої попередньої згоди. Ми розкриваємо персональні дані лише у випадках визначених чинним законодавством України, а також:\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            Ми розкриємо інформацію в випадку запобігання злочину або завдання шкоди нам або третім особам;\n" +
                    "                                            Ми розкриємо інформацію третім особам, що надають нам технічну підтримку та послуги за допомогою яких Ви отримуєте Ваше замовлення та користуєтесь комп’ютерними програмами.\n" +
                    "                                            Ви погоджуєтесь не передавати персональні дані третіх осіб. Ви надаєте згоду не передавати персональні дані, які містяться у комп’ютерній програмі третім особам. Використовуючи персональні дані Ви надаєте згоду дотримуватись чинного законодавства України щодо захисту персональних даних. ТОВ «БК «Інтергал-Буд» не несе відповідальності за незаконне використання персональних даних користувачами комп’ютерної програми.\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            <b>4. Безпека</b>\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            Ми приймає розумні заходи для захисту інформації від втрати, крадіжки, неправильного використання та несанкціонованого доступу, розкриття, зміни та знищення.\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            <b>5. Місцезнаходження персональних даних</b>\n" +
                    "\n" +
                    "                                        </p>\n" +
                    "                                        <p>\n" +
                    "                                            Власником персональних даних є «zhytomyr.land». Персональні дані зберігаються в автоматизованих системах «zhytomyr.land»\n" +
                    "                                        </p>\n",
                en: "<p>\n" +
                    "                                                According to the Law of Ukraine \"On the Protection of Personal Data\", by filling in the data of the registration form (order) and marking the fact that you have familiarized yourself with this Privacy and Personal Data Processing Policy (hereinafter - the Privacy Policy), you consent to the processing of your personal data in order to ensure the implementation of civil legal relations, when issuing/extending a non-exclusive license (s) for the use of a computer program (s).\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                We value your right to privacy and non-disclosure of your personal data. This Privacy Policy is the rules used by all employees of our company and regulates the collection and use of personal data that may be requested/received when purchasing computer program(s).\n" +
                    "                                            </p>\n" +
                    "                                            <p className={''}>\n" +
                    "                                                <b>1. Volume of personal data</b>\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                When placing an order for the purchase of computer program(s) and filling out the registration form (order), you provide your personal data for processing - in particular, name, address, phone number, e-mail, bank data, etc. We receive such data only from persons who provide it knowingly and voluntarily. In order to place an order for computer program(s) or otherwise interact with us, you should carefully familiarize yourself with your rights and obligations regarding the processing of personal data, which are specified in Art. 8 of the Law of Ukraine \"On the Protection of Personal Data\", carefully familiarize yourself with this Privacy Policy, as well as express your full agreement with its terms.\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                <b>2. The purpose of collecting personal data</b>\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                Personal data - information or a set of information about a natural person who is identified or can be specifically identified. We may process your personal data for the following purposes. At the same time, one or more goals can be applied at the same time:\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                Order receipt. We may use your personal data to receive the order you placed, to process your requests, or for other purposes that may exist to achieve the ultimate goal - to satisfy the interests of the consumer, as well as to prevent and investigate cases of fraud and other abuses. Communication with you. We may use your personal data to communicate with you, for example to inform you about a change in our products or to send you important messages and other similar messages related to the order you have placed and to contact you for the purposes of related to consumer/client service. We act in accordance with this Privacy Policy, on the basis of the Regulation on the Processing and Protection of Personal Data and on the basis of the current legislation of Ukraine. We have the right to store Personal Data as long as it is necessary to implement the purpose specified in this Policy or within the terms established by current legislation of Ukraine or until you delete this data.\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                <b>3. Transfer of personal data to third parties</b>\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                We do not sell, transfer or disclose personal data we receive on our website to third parties without your prior consent. We disclose personal data only in cases determined by the current legislation of Ukraine, as well as:\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                We will disclose information to prevent crime or harm to us or third parties; We will disclose information to third parties who provide us with technical support and services through which you receive your order and use computer programs. You agree not to share personal data with third parties. You agree not to transfer personal data contained in the computer program to third parties. By using personal data, you agree to comply with the current legislation of Ukraine on the protection of personal data. BC \"Intergal-Bud\" LLC is not responsible for the illegal use of personal data by users of the computer program.\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                <b>4. Safety</b>\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                We take reasonable measures to protect information from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                <b>5. Location of personal data</b>\n" +
                    "                                            </p>\n" +
                    "                                            <p>\n" +
                    "                                                The owner of personal data is \"zhytomyr.land\". Personal data is stored in the automated systems of \"zhytomyr.land\"\n" +
                    "                                            </p>"
            }
        },
        qa: {
            en: [
                {
                    link: "1",
                    q: "How to sign up for an excursion?",
                    a: "You can sign up for a tour by calling +380 67 281 38 46. Our manager will meet you on the territory and show you the project, as well as answer all your questions. You will learn about the principles of territory planning, landscaping, evaluate the functionality of the plans and the quality materials."
                },
                {
                    link: "2",
                    q: "How to buy a plot of land?",
                    a: "Contact us by phone +380 67 281 38 46 to clarify the availability of the land plot you are interested in according to the master plan, after which we will be happy to give you a tour, answer all the questions you may have, the next step is to draw up a purchase and sale agreement, make a calculation, and receive a house/townhouse project."
                },
                {
                    link: "3",
                    q:"Purchase Terms",
                    a: "Purchasing a land plot is carried out by drawing up a sales contract. The calculation for a land plot is possible with a review of the individual needs of the buyer."
                },
                {
                    link: "4",
                    q: "What documents are required for purchase?",
                    a: "For the signing of the agreement, a passport of a citizen of Ukraine, TIN, marriage registration certificate (if married), passport and TIN of the husband/wife are required."
                },
                {
                    link: "5",
                    q: "Can a non-resident of Ukraine purchase?",
                    a: "Yes, provided the taxpayer's registration card (identification code) and a notarized translation of the passport are available."
                }
            ],
            ua: [
                {
                    link: "1",
                    q: "Як записатися на екскурсію?",
                    a: "Ви можете записатися на екскурсію за номером телефон +380 67 281 38 46. Наш менеджер зустріне вас на териториї і покаже проект, а також відповість на всі ваші питання. Ви дізнаєтесь про принципи планування території, благоустрій, оціните функціональність планувань і якість матеріалів."
                },
                {
                    link: "2",
                    q: "Як купити земельну ділянку?",
                    a: "Зв'яжіться з нами за телефоном +380 67 281 38 46 для уточнення наявності у продажу земемельної ділянки, що вас цікавить відповідно до генерального плану, після чого ми зрадістю проведемо вам екскурсію, відповімо на всі питання, що у вас виникнуть, наступним кроком є оформлення договору купівлі продажу, здійснення розрахунку, отримання проекту будинка/таунхауса."
                },
                {
                    link: "3",
                    q:"Умови придбання",
                    a:"Придбання земельної ділянки здійснюється шляхом оформлення договору купівлі-продажу. Розрахунок за земельну ділянку можливий із переглядом індивідуальних потреб покупця."
                },
                {
                    link: "4",
                    q: "Які документи необхідні для покупки?",
                    a: "Для підписання угоди необхідний Паспорт громадянина України, ІПН, свідоцтво про реєстрацію шлюбу (якщо в шлюбі), паспорт та ІПН чоловіка/дружини."
                },
                {
                    link: "5",
                    q: "Чи може придбати нерезидент України?",
                    a: "Так, за умови наявності облікової картки платника податків (ідентифікаційного коду) і перекладу паспорта, який завірений нотаріально."
                }
            ]
        }

    }];

export default DATA_INFO;