import HeaderComponent from "../components/default/header";
import MainPage from "./main";
import FooterComponent from "../components/default/footer";
import React, {Suspense} from "react";
import ICONS from "../components/default/icons";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ModalForm from "../components/default/modal";

function ContactsPage() {

    const _icon = ICONS[0];

    const { t, i18n } = useTranslation()

    return (

        <>
            <div className={'container m-auto'} style={{ paddingTop: "15vh"}}>
                <h1 className={'display-1'}>
                    {t('nav.contacts')}
                </h1>
            </div>
            <div className="">

                <div className="border m-5 p-3 shadow position-absolute bg-white h-50" style={{width: '300px'}}>


                    <p className={'fw-bold'}>
                        {t('page.location')} {_icon.location} c.Іванівка
                    </p>


                    <p>
                        <a
                            className="phone-link fw-light text-decoration-none color"
                            href="tel.:+380 67 281 38 46"
                            // target="_blank"
                            rel="noopener noreferrer"
                        >
                            {_icon.phone}  +380 67 281 38 46
                        </a>
                    </p>

                    <p className={''}>
                        <a
                            className="phone-link fw-light text-decoration-none color"
                            href="mailto:zhytomyr.land@gmail.com"
                            // target="_blank"
                            rel="noopener noreferrer"
                        >
                            {_icon.email}  zhytomyr.land@gmail.com
                        </a>
                    </p>

                    {
                        i18n.language === 'ua' &&  <p className={'fw-light mt-4'}>
                            Пн-Пт: 09:00 - 19:00 <br/>
                            Сб: 10:00 - 19:00 <br/>
                            Нд: Вихідний <br/>
                        </p>
                    }

                    {
                        i18n.language === 'en' &&  <p className={'fw-light mt-4'}>
                            Mon-Fri: 9am- 7pm <br/>
                            Sat: 10am - 7pm <br/>
                            Sun: Day off <br/>
                        </p>
                    }

                    <ul className={'list-unstyled pt-2'}>
                        <li className="">
                            <a href="https://t.me/+380672813846" className={'m-1 text-decoration-none text-blue'}>
                                {_icon.telegram} Telegram
                            </a>
                        </li>

                        <li className="">
                            <a href="whatsapp://send?phone=+380672813846" className={'m-1 text-decoration-none color'}>
                                {_icon.whatsapp} WhatsApp
                            </a>
                        </li>

                        <li className="">
                            <a href="viber://contact?number=%2B0672813846" className={'m-1 text-decoration-none'} style={{color: 'purple'}}>
                                {_icon.viber} Viber
                            </a>
                        </li>

                        <li className="">
                            <a href="https://signal.me/#p/+380672813846" className={'m-1 text-decoration-none text-primary'}>
                                {_icon.signal} Signal
                            </a>
                        </li>
                    </ul>

                    <ModalForm
                        type={'lead'}
                        title={t('page.btn-connect')}
                        class={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3 w-100'}
                    />
                </div>
                <Suspense fallback={<div>{t('page.load')}</div>}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d6207.648768246543!2d28.540552285736094!3d50.283499832725546!3m2!1i1024!2i768!4f13.1!5e1!3m2!1suk!2sua!4v1718236149708!5m2!1suk!2sua"
                        width="100%" style={{height: '70vh'}} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade" />
                </Suspense>
            </div>

        </>



    );
}

export default ContactsPage;