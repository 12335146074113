import 'react-medium-image-zoom/dist/styles.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import MainPage from "./pages/main";
import {BrowserRouter} from "react-router-dom";
import {Route, Routes} from "react-router";
import Layout from "./components/page/layout";
import ContactsPage from "./pages/contacts";
import NoPage from "./pages/404";
import AboutPage from "./pages/about";
import ProjectsPage from "./pages/projects";
import PostsPage from "./pages/posts";
import InfoPage from "./pages/info";
import './i18n';
import PostSingle from "./pages/single/post";
import ProjectSingle from "./pages/single/project";
import '@splidejs/react-splide/css';
import LandPage from "./pages/land";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Layout />}>
                  <Route index element={<MainPage />} />

                  <Route path="about-us" element={<AboutPage />} />

                  <Route path="projects" element={<ProjectsPage />} />
                  <Route path="projects/:slug" element={<ProjectSingle />} />

                  <Route path="land" element={<LandPage />} />

                  <Route path="posts" element={<PostsPage />} />
                  <Route path="posts/:slug" element={<PostSingle />} />

                  <Route path="faq" element={<InfoPage />} />
                  <Route path="info" element={<InfoPage />} />
                  <Route path="information" element={<InfoPage />} />

                  <Route path="contacts" element={<ContactsPage />} />

                  <Route path="*" element={<NoPage />} />
              </Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
