import React from 'react';
import {useTranslation} from "react-i18next";

function LanguageComponent() {

    const { t, i18n } = useTranslation()

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
    }

    return (<>

            {/*{i18n.language}*/}
            <div id="lang-switch" onChange={changeLanguage} className={''}>
                <label className="radio-img p-1 flag-language">
                    <input type="radio" value="ua" name="language" defaultChecked/>
                    <span className={i18n.language === 'ua' ? 'active_language fw-bold' : 'text-secondary'}>UA</span>
                </label>

                <label className="radio-img p-1 flag-language">
                    <input type="radio" value="en" name="language"/>
                    <span className={i18n.language === 'en' ? 'active_language fw-bold' : 'text-secondary'}>EN</span>
                </label>

                {/*<label className="radio-img p-1 flag-language">*/}
                {/*    <input type="radio" value="ru" name="language"/>*/}
                {/*    <span className={i18n.language === 'ru' ? 'active_language fw-bold' : 'text-secondary'}>RU</span>*/}
                {/*</label>*/}

            </div>
    </>);
}

export default LanguageComponent;
