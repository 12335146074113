import { Nav, Navbar, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import LanguageComponent from "./language";
import React from "react";
import {useTranslation} from "react-i18next";

const Navigationbar = () => {

    const { t, i18n } = useTranslation()

    return (
        <Navbar collapseOnSelect expand="sm" className={'bg-light fixed-top p-0'}>

            <div className={'w-100 background p-2 d-flex align-items-center justify-content-between'}>



                <div className={'d-flex align-items-center justify-content-center'}>
                    <Link to="/" className={'d-flex align-items-center'}>
                        <img src="/assets/img/logo.png" alt="logo" className={'m-1'} width={'50'}/>
                        <h4 className={'color fs-2'}>
                            Zhytomyr Land
                        </h4>
                    </Link>
                </div>

               <div className={'d-flex'}>
                   <div className="p-2">
                       <LanguageComponent />
                   </div>

                   <Navbar.Toggle className={'border-0'} aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll"/>
               </div>
            </div>

            <Navbar.Collapse id="navbarScroll" className={''}>
                <Nav className={'p-2 shadow'}>
                    <NavLink className={'fs-3'} eventKey="1" as={Link} to="/">Головна</NavLink>
                    <NavLink className={'fs-3'} eventKey="2" as={Link} to="/about-us">{t("nav.about")}</NavLink>
                    <NavLink className={'fs-3'} eventKey="3" as={Link} to="/projects"> {t('nav.projects')}</NavLink>
                    <NavLink className={'fs-3'} eventKey="4" as={Link} to="/land"> {t('nav.land')}</NavLink>
                    <NavLink className={'fs-3'} eventKey="5" as={Link} to="/posts">{t('nav.posts')}</NavLink>
                    <NavLink className={'fs-3'} eventKey="6" as={Link} to="/faq"> {t('nav.faq')}</NavLink>
                    <NavLink className={'fs-3'} eventKey="7" as={Link} to="/contacts">{t('nav.contacts')}</NavLink>

                    <div className="text-center border-top pt-4 pb-3">

                        <a
                            className={'fs-2'}
                            href="tel.:+380 67 281 38 46"
                            // target="_blank"
                            rel="noopener noreferrer"
                        >
                            +380 67 281 38 46
                        </a>
                    </div>
                </Nav>

            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigationbar;