import { Outlet, Link } from "react-router-dom";
import React, {useEffect} from "react";
import FooterComponent from "../default/footer";
import HeaderComponent from "../default/header";
import LayoutContentPage from "../default/layout";
import ScrollToTop from "react-scroll-to-top";
import ICONS from "../default/icons";
import axios from "axios";
import ModalForm from "../default/modal";

const Layout = () => {

    const _text = 'Добрий день, цікавить земельна ділянка, коли можна переглянути?';
    const _pass = 'luka';

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const view = params.get('dev');

    useEffect(() => {

        axios.post('https://api.zhytomyr.land/api/detected', [], {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        }).then((response) => {
            console.log(response.data)
        });
    }, [])

    return (
        <>

            {
                // view === _pass ?
                true ?
                <div className="page">
                    <HeaderComponent />

                    <LayoutContentPage>
                        <Outlet />
                    </LayoutContentPage>

                    <FooterComponent />
                </div> : <div className={'p-2'}>
                    <p className="display-1">
                        No access!
                    </p>
                </div>
            }

            <a className="fixedButton" href={'whatsapp://send?phone=+380672813846&text='  + _text} target="_blank">
                <div className="roundedFixedBtn">
                    {ICONS[0].whatsapp2}
                </div>
            </a>
            <div className="fixedButton2">

                    <ModalForm
                        // title={'Консультація'}
                        type={'recall'}
                        class={'bg-transparent border-0'}
                        icon={<div className="roundedFixedBtn2 shadow-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                 className="bi bi-telephone-inbound-fill" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0"/>
                            </svg>
                        </div>}
                    />

            </div>
            <ScrollToTop smooth className={'background shadow-lg'} color={'#00A66F'} />
        </>
    )
};

export default Layout;