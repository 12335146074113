import ICONS from "../components/default/icons";
import ModalForm from "../components/default/modal";
import React, {Suspense} from "react";
import {useTranslation} from "react-i18next";
import Zoom from 'react-medium-image-zoom';
import {MotionAnimate} from "react-motion-animate";
import SectionComponent from "../components/default/section";

function LandPage() {

    const { t, i18n } = useTranslation()

    const _lang = i18n.language;

    const _options = [
        {
            icon: ICONS[0].location64,
            label: {
                ua: "м.Житомир (с.Іванівка)",
                en: "Zhytomyr city (с.Іванівка)"
            }
        },
        {
            icon: ICONS[0].land64,
            label: {
                ua: "від 5 - 10 сот.",
                en: "from 5 - 10 hun."
            }
        },
        {
            icon: ICONS[0].walking,
            label: {
                ua: "15-20 хв. від міста",
                en: "15-20 m. to City"
            }
        },
        {
            icon: ICONS[0].money64,
            label: {
                ua: "від $900/сот.",
                en: "from $900/hun."
            }
        },{
            icon: ICONS[0].light64,
            label: {
                ua: "Готові комунікації",
                en: "Connected communications"
            }
        }
        ,{
            icon: ICONS[0].house2,
            label: {
                ua: "Готовий проект будинку/таунхауса",
                en: "Ready project of the house/town-house"
            }
        },{
            icon: ICONS[0].shop,
            label: {
                ua: "Інфраструктура",
                en: "Infrastructure"
            }
        },{
            icon: ICONS[0].tree,
            label: {
                ua: "Еколочіснітсь (водойма, ліс, свіже повітрая)",
                en: "Clean up the environment (reservoirs, forest, fresh air)"
            }
        }
    ];


    return (

        <div className={'container m-auto'} style={{ paddingTop: "15vh"}}>

            <Suspense fallback={<div>{t('page.load')}</div>}>
            <div className="d-flex flex-wrap justify-content-between align-items-center mb-5 pt-5">
                <div className={'col-md-8 col-12'}>
                    <h1 className={'display-1'}>
                        {t('nav.lands')}
                    </h1>
                    <small className="text-secondary">
                        {t('subtitle')}
                    </small>

                    <p className={'lead'}>
                        {t('page.project-land')}
                    </p>
                </div>

                <div className={'col-md-4 col-12 text-end d-none d-md-block'}>
                    <a href=" https://kadastr.live/#16.21/50.283381/28.539743" target={'_blank'} className={'btn rounded-0 text-decoration-none bg-white color p-3'}>
                        {ICONS[0].location} {t('nav.kadastr-map')}
                    </a>
                    <a href="https://www.google.com/maps/@50.2835821,28.5376555,889m/data=!3m1!1e3?entry=ttu" className={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3'} target={'_blank'}>{ICONS[0].location} Google {t('nav.map')}</a>
                </div>
            </div>
            </Suspense>
            <Suspense fallback={<div>{t('page.load')}</div>}>
            <MotionAnimate
                delay={0.4}
                speed={2}
                ease={[0.75, 0.45, 0.53, 0.94]}
                reset={true}>
            <div className="border">
                <Zoom>
                    <img src="/assets/img/plan.webp" alt="plan-land-plots" className="img-fluid"/>
                </Zoom>
                <br/>
                <small className="text-secondary">
                    <i>
                        {t('nav.main-plan')}
                    </i>
                </small>
            </div>
            </MotionAnimate>
            </Suspense>
            <div className={'d-flex col-12 text-end d-block d-md-none mt-5'}>
                <a href=" https://kadastr.live/#16.21/50.283381/28.539743" target={'_blank'} className={'btn rounded-0 border text-decoration-none bg-white color p-3'}>
                    {ICONS[0].location} {t('nav.kadastr-map')}
                </a>
                <a href="https://www.google.com/maps/@50.2835821,28.5376555,889m/data=!3m1!1e3?entry=ttu" className={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3'} target={'_blank'}>{ICONS[0].location} Google {t('nav.map')}</a>
            </div>

            {/*https://cdn.kadastr.live/tiles/raster/styles/parcels/{z}/{x}/{y}.png*/}
            <Suspense fallback={<div>{t('page.load')}</div>}>
            <div className="p-3">

                <h4 className={'mt-5 mb-5 display-4'}>{t('nav.land-options')}</h4>

                <div className="d-flex flex-wrap justify-content-between">

                    {
                        _options.map((option, i) => <div key={i} className="p-2 col-md-3 col-6">
                            <MotionAnimate
                                delay={0.2 + i}
                                speed={1.5}
                                ease={[0.75, 0.45, 0.53, 0.94]}
                                reset={true}>  <div className={'p-2 display-1'}>
                                {option.icon}
                            </div>
                            <h5 className="text-secondary lead">
                                {option.label[_lang]}
                            </h5>
                            </MotionAnimate>
                        </div>)
                    }

                </div>

            </div>
            </Suspense>

            <div className="col-md-4 col-12 m-auto mt-5 mb-5">
                <ModalForm
                    type={'lead'}
                    title={t('page.btn-consultation').toUpperCase()}
                    class={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3 w-100'}
                />
            </div>
            <Suspense fallback={<div>{t('page.load')}</div>}>
            <br/>
            <MotionAnimate animation='fadeInUp' reset={true}>
            <h3 className={'display-1'}>{t('nav.kadastr-map')}</h3>
            <iframe
                src="https://kadastr.live/?dzk__index_map_lines=false&dzk__pzf=false&water_poly_ramsar=false&nsdi_sm_merega=false&water_lines_other=false&river_basin=false&river_subbasin=false&dzk__atu_oblast=true&dzk__atu_rayon=true&dzk__atu_terhromad__line=true#16.67/50.283565/28.538481"
                frameBorder="0" width={'100%'} height={'700px'}/>
            </MotionAnimate>

            <div className="col-md-4 col-12 m-auto mt-5 mb-5">
                <ModalForm
                    type={'lead'}
                    title={t('page.btn-connect').toUpperCase()}
                    class={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3 w-100'}
                    // icon={ICONS[0].phone}
                />
            </div>
            </Suspense>
        </div>

    );
}

export default LandPage;
