import { Splide, SplideSlide } from '@splidejs/react-splide';
import {useParams} from "react-router";
import AdvantagesContent from "../../content/advantages";
import ModalForm from "../../components/default/modal";
import React, {lazy, Suspense, useEffect, useState} from "react";

import DATA_HOUSE from '../../data/house.json';
import DATA_TOWNHOUSE from '../../data/town-house.json';
import {useTranslation} from "react-i18next";
import Zoom from "react-medium-image-zoom";
import {MotionAnimate} from "react-motion-animate";

const BlockAdvantages = lazy(() => import('../../content/advantages'));

function ProjectSingle(){

    let {slug} = useParams();

    const data = slug === 'house' ? DATA_HOUSE : DATA_TOWNHOUSE;

    const { t, i18n } = useTranslation()

    const _lang = i18n.language;

    return <>
        <div className="container-fluid pt-5">
            <Zoom>
                <img
                    style={{height: slug === 'house' ? '70vh' : '40vh', width: "100%", objectFit: "cover"}}
                    src={data.cover} alt="image-cover" className={'img-fluid rounded-bottom'}
                />
            </Zoom>
        </div>
        <div className="container col-md-6 m-auto col-12">

            <h1 className={'mt-5'}>
                {data.title[_lang].toUpperCase()}
            </h1>

            <div className={'text-secondary'}>
                <small>{t('nav.projects').substring(0, t('nav.projects').length - 1)}</small>
            </div>
            <MotionAnimate
                delay={0.4}
                speed={2}
                ease={[0.75, 0.45, 0.53, 0.94]}
                reset={true}>
            <div className="row mt-5">
                <div className="col-md-6">

                    <h3>
                        {t('title-nav.options')}
                    </h3>
                    <div className="d-flex flex-wrap mt-4">
                        {
                            data.options.map((option, i) => <div key={i} className={'col-md-6 col-6 p-3'}>

                                <div dangerouslySetInnerHTML={{__html: option.icon ?? '-' }} />

                                <br/>

                                <small className="text-secondary">
                                    {
                                        option.name[_lang]
                                    }
                                </small>
                            </div>)
                        }
                    </div>

                </div>

                <div className="col-md-6">
                    <p>
                        {data.description[_lang]}
                    </p>
                    <ModalForm
                        type={'lead'}
                        title={t('page.btn-consultation')}
                        class={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3 col-md-12 col-12'}
                        // icon={ICONS[0].phone}
                    />
                </div>
            </div>
            </MotionAnimate>
            <div className={'mt-5'}>
                <h3>{t('title-nav.gallery')}</h3>
                <Splide
                    options={ {
                        rewind: true,
                        rewindByDrag: true,
                        gap   : '1rem',
                        // width: 500,
                        perPage: 3,
                        // height : '50vh',
                        // rewind : true,
                        // gap    : '1rem',
                        autoplay: true,
                        drag: 'free',
                    } }
                    aria-label="Town house Images"

                >
                    {
                        data.images.map((img, i) =>   <SplideSlide key={i}>
                            <Zoom> <img src={img.url} alt={img.label} className={'img-fluid'}/></Zoom>
                        </SplideSlide>)
                    }
                </Splide>
            </div>

            <div className={''}>
                <Suspense fallback={<div>{t('page.load')}</div>}>
                    <BlockAdvantages />
                </Suspense>
            </div>

            <div>
                <h3>{t('nav.land')}</h3>

                <p>
                    {t('page.project-land')}
                </p>

                <a href="/land" className={'text-decoration-none color'} target={'_blank'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                    </svg>
                    {t('page.detail-land-link')}
                </a>
            </div>
            <MotionAnimate
                delay={0.4}
                speed={2}
                ease={[0.75, 0.45, 0.53, 0.94]}
                reset={true}>
            <div className={'mt-5'}>
                <h3>
                    {t('title-nav.planning')}
                </h3>
                <div className="d-flex flex-wrap">
                    {
                        data.planing.map((plan, i) =>  <div key={i} className="col-md-6 col-12 p-1">
                            <div className="border border-dark">
                                <Zoom>
                                    <img src={plan.url} alt={plan.label} className={'img-fluid'}/>
                                </Zoom>
                            </div>
                        </div>)
                    }
                </div>
            </div>
            </MotionAnimate>
            <div>
                <ModalForm
                    type={'lead'}
                    title={t('page.btn-connect')}
                    class={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3 col-md-4 col-12'}
                />
            </div>

        </div>
    </>
}

export default ProjectSingle;