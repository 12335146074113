import ProjectsContent from "../content/projects";
import ICONS from "../components/default/icons";
import ModalForm from "../components/default/modal";
import React from "react";

function ProjectsPage() {
    return (

        <div className={'container m-auto'} style={{ paddingTop: "1vh"}}>
            {/*<h1>*/}
            {/*    Проекти*/}
            {/*</h1>*/}

            <ProjectsContent />

            <ModalForm
                type={'lead'}
                title={'Зв\'язатись'}
                class={'btn btn-dark rounded-0 shadow text-decoration-none color background p-3 col-md-4 col-12'}
                icon={ICONS[0].phone}
            />

        </div>

    );
}

export default ProjectsPage;
