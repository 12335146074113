import ICONS from "../components/default/icons";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {MotionAnimate} from "react-motion-animate";



function PostsContent (props) {

    const { t, i18n } = useTranslation()

    const [data, setData] = useState([]);
    const [load, setLoad] = useState(true);

    //TODO: add new posts https://dewpoint.com.ua/uk/yak-obrati-pidryadnika-dlya-budivnitstva-budinku-5-korisnih-porad/
    //https://mayak.kiev.ua/news/shcho-take-taunkhaus
    //https://redwall.com.ua/blog/taunhaus-chto-eto-takoe-i-chem-on-otlichaetsya-ot-kvartir-i-domov

    const option = props.limit ? '&onPage=4': '';

    const _fetch = () => {
        axios.get('https://api.zhytomyr.land/api/content/posts?sort=DESC' + option, {
            header: {}
        }).then( response => setData(response.data.data.posts))
            .catch(e => alert(e.message()))
            .finally( () => setLoad(false))
    }

    useEffect(() => {
        _fetch();
    }, []);

    return <>
            <div className="container mt-5 mb-5 p-0 m-0">
                <h2 className={'display-3'}>
                    {t('nav.posts')}
                </h2>

                {load && t('page.load')}
                <MotionAnimate
                    delay={0.4}
                    speed={2}
                    ease={[0.75, 0.45, 0.53, 0.94]}
                    reset={true}>
                <div className="d-flex flex-wrap border-top border-black mt-5 pt-5">

                    {

                            data.map((post, i) => <div key={i} className="col-md-3 col-12">
                                <div className="m-2 p-4 pt-3 pb-2 border-end border-black">
                                    <a href={'/posts/' + post.slug} target={'_blank'} className={'text-decoration-none'}>

                                        {/*https://images.unsplash.com/photo-1629016943072-0bf0ce4e2608*/}
                                        {post.image && <img src={post.image} alt="image" className={'img-fluid mb-3'}/>}

                                        <span className="color text-uppercase">
                                        {  i18n.language === 'ua' ? post.category.name.ua : post.category.name.en}
                                </span>
                                        <h5 className={'text-dark'}>{
                                            i18n.language === 'ua' ? post.title.ua :
                                                post.title.en
                                        }</h5>

                                        <small className="text-secondary">
                                            {post.data}
                                        </small>
                                    </a>
                                </div>
                            </div>)

                    }

                </div>
                </MotionAnimate>
                {
                    props.page === false && <div className="text-center pt-5">
                    <a href="/posts" className={'btn border color rounded-0 p-3'} target={'_blank'}>{t('page.btn-more')}</a>
                    </div>
                }

            </div>
        </>
}

export default PostsContent;