import ICONS from "../components/default/icons";

const DATA_PROJECTS = [
    {
        id: 1,
        title: {
            ua: "Земельні ділянки",
            en: "Land plots",
        },
        description: {
            ua: "Опис",
            en: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet at beatae cumque, est eveniet fugiat iusto maxime, minus nam nisi, obcaecati perspiciatis provident rem soluta tempore. Aspernatur mollitia odio voluptatum?",
        },
        slug: "land",
        url: "/assets/img/land2.webp",
        options: [
            {
                name: {
                    en: "Zhytomyr city (с.Іванівка)",
                    ua: "м.Житомир (с.Іванівка)"
                },
                icon: ICONS[0].location,
            },
            {
                name: {
                    en: "Connected communications",
                    ua: "Підключені комунікації"
                },
                icon: ICONS[0].light,
            },
            {
                name: {
                    en: "from 900/hun.",
                    ua: "від 900/сот."},
                icon: ICONS[0].money
            },
            {
                name: {
                    en: "x3 types of land plots",
                    ua: "x3 вида земельних ділянок"
                },
                icon: ICONS[0].land,
            },
        ]
    },
    {
        id: 2,
        title: {
            ua: "Будинок",
            en: "House",
        },
        description: {
            ua: "Опис",
            en: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet at beatae cumque, est eveniet fugiat iusto maxime, minus nam nisi, obcaecati perspiciatis provident rem soluta tempore. Aspernatur mollitia odio voluptatum?",
        },
        slug: "/projects/house",
        url: "/assets/img/house.webp",
        options: [
            {
                name: {
                    en: "Zhytomyr city (с.Іванівка)",
                    ua: "м.Житомир (с.Іванівка)"
                },
                icon: ICONS[0].location,
            },
            {
                name: {
                    en: " from 5 hun. up to 10 hun.",
                    ua: " від 5 сот. до 10 сот."
                },
                icon: ICONS[0].land,
            },
            {
                name: {
                    en: " from 140 m2 to 170 m2",
                    ua: " від 140 m2 до 170 m2"
                },
                icon: ICONS[0].house,
            }
        ]
    },
    {
        id: 3,
        title: {
            ua: "Таунхаус",
            en: "Town-house",
        },
        description: {
            ua: "Таунхаус – це гібрид міської квартири та заміського будинку. Цей тип забудови складається з блоків дво- або триповерхових квартир із загальними стінами, але обов’язково з окремими входами.",
            en: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet at beatae cumque, est eveniet fugiat iusto maxime, minus nam nisi, obcaecati perspiciatis provident rem soluta tempore. Aspernatur mollitia odio voluptatum?",
        },
        slug: "/projects/town-house",
        url: "/assets/img/town-house.webp",
        options: [
            {
                name: {
                    en: "Zhytomyr city (с.Іванівка)",
                    ua: "м.Житомир (с.Іванівка)"
                },
                icon: ICONS[0].location,
            },
            {
                name: {
                    en: "3 hun.",
                    ua: " 3 сот."
                },
                icon: ICONS[0].land,
            },
            {
                name: {
                    en: "100 m2",
                    ua: "100 m2"
                },
                icon: ICONS[0].house,
            }
        ]
    },
];

export default DATA_PROJECTS;