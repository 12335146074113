import {useTranslation} from "react-i18next";
import GoalsContent from "./goals";
import {MotionAnimate} from "react-motion-animate";
import React from "react";
import Zoom from 'react-medium-image-zoom'


function AboutContent () {

    const { t, i18n } = useTranslation()

    const _lang = i18n.language;

    return <>
            <div className="container row mt-5 mb-5 p-0 m-0 _flex-sm-row-reverse align-items-center">
                <div className="col-md-6 col-12 text- d-none d-md-block">
                    <div className="image-cover">
                        <Zoom>
                            <img src="/assets/img/about.webp" alt="about image" className={'img-fluid'}/>
                        </Zoom>
                    </div>

                    <MotionAnimate
                        delay={0.4}
                        speed={2}
                        ease={[0.75, 0.45, 0.53, 0.94]}
                        reset={true}>
                        <GoalsContent />
                    </MotionAnimate>

                </div>

                <div className="col-md-6 col-12">
                    <h2>
                        Zhytomyr Land
                    </h2>
                    <small className="text-secondary">
                        {t('subtitle')}
                    </small>

                    <p>
                        {t('page.project-land')}
                    </p>
                    <p>
                        {t('about.block4')}
                    </p>

                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item1 rounded-0 bg-color">
                            <h2 className="accordion-header bg-color" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    {t('about.title1')}
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show"
                                 aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>
                                        {t('about.block1')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item1 border-top">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    {t('about.title2')}
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <h6>
                                        <b>{t('about.block2.title1')}</b>
                                    </h6>
                                    <p>
                                        {t('about.block2.module1')}
                                    </p>

                                    <h6>
                                        <b>{t('about.block2.title2')}</b>
                                    </h6>
                                    <p>
                                        {t('about.block2.module2')}
                                    </p>

                                    <h6>
                                        <b>{t('about.block2.title3')}</b>
                                    </h6>
                                    <p>
                                        {t('about.block2.module3')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item1 border-top rounded-0">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                    {t('about.title3')}
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse"
                                 aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                   <p>
                                       {t('about.block3')}
                                   </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
}

export default AboutContent;